import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Avatar } from "../ProfileModels";
import {
  UserWallet,
  FreeWinPostTransBalance,
  TronAddress,
  FreeWinBalance,
  GetTransaction,
  TransactionHistory,
  FreeWinURL,
  TokenFrom2FA,
  TotpResponse,
  BodyWithdrawFromFreewinApiFreewinWithdrawPost,
  BodyWithdrawWalletApiTronUsdtWithdrawPost,
  UserSettingsSchemas,
  VerifyTransaction,
  FreeWinLocale,
  ShowUserForUser,
  Ps3838TransBalance,
  BodyWithdrawFromFreewinApiBanksWithdrawPost,
} from "../../../../api/core-contract";

const API_URL = process.env.REACT_APP_API_URL;
const GET_WALLET = `${API_URL}/wallet/balance`;

const GET_TRON_WALLET = `${API_URL}/tron/wallet`;

const SET_DEPOSIT_FREEWIN = `${API_URL}/freewin/deposit`;

const SET_DEPOSIT_PS3838 = `${API_URL}/ps3838/deposit`;

const GET_FREEWIN_BALANCE = `${API_URL}/freewin/balance`;

const WITHDRAW_DEPOSIT_FREEWIN = `${API_URL}/freewin/withdraw`;

const WITHDRAW_DEPOSIT_PS3838 = `${API_URL}/ps3838/withdraw`;

const FREEWIN_LOGIN_RU = `${API_URL}/freewin/login_ru`;

const GET_WALLET_TRANSACTION = `${API_URL}/wallet/transactions`;

const GET_ALL_AVATARS = `${API_URL}/settings/avatar/all`; // Показать доступные аватары
const CHOOSE_AVATAR = `${API_URL}/settings/avatar/choose_avatar`; // Выбрать аватар

const MY_BETS = `${API_URL}/freewin/my-bets`;

const WITHDRAW_TRON = `${API_URL}/tron/usdt/withdraw`;

const WALLET_VERIFICATION = `${API_URL}/wallet/verification`;

const GET_TOTP_URL = `${API_URL}/totp`;

const RESET_TOTP_URL = `${API_URL}/totp/reset`;

const USER_DATA = `${API_URL}/user`;

const CHANGE_USER_DATA = `${API_URL}/user/settings`;

const GET_TOTP_SECRET_CODE = `${API_URL}/totp/get`;

const CONFIRM_TOTP = `${API_URL}/totp/confirm`;

const WITHDRAW_DEPOSIT_BANKS = `${API_URL}/freewin/withdraw/banks`;

export const useAllAvatar = () => {
  return useMutation({
    mutationFn: () => {
      return axios.get(GET_ALL_AVATARS);
    },
  });
};

export const useMyBets = (options: any = {}) => {
  return useQuery<FreeWinURL>(
    "my-bets",
    async () => {
      const { data } = await axios.get(MY_BETS);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useWalletVerification = (options: any = {}) => {
  return useQuery<FreeWinURL>(
    "wallet_verification",
    async () => {
      const { data } = await axios.get(WALLET_VERIFICATION);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useChooseAvatar = () => {
  return useMutation<any, any, Avatar>({
    mutationFn: body => {
      return axios.put(CHOOSE_AVATAR, body);
    },
  });
};

export const useWallet = (options: any = {}) => {
  return useQuery<UserWallet>(
    "wallet",
    async () => {
      const { data } = await axios.get(GET_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useTronWallet = (options: any = {}) => {
  return useQuery<TronAddress>(
    "tron-wallet",
    async () => {
      const { data } = await axios.get(GET_TRON_WALLET);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useWalletTransactions = (options: any = {}) => {
  return useMutation<{ data: TransactionHistory[] }, any, GetTransaction>({
    mutationFn: async data => {
      const response = await axios.post(GET_WALLET_TRANSACTION, data);
      const totalPages = response.headers["x-total-pages"];
      return {
        data: response.data,
        totalPages,
      };
    },
  });
};

export const useFreewinBalance = (options: any = {}) => {
  return useQuery<FreeWinBalance>(
    "freewin-balance",
    async () => {
      const { data } = await axios.get(GET_FREEWIN_BALANCE);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useDepositFreewin = () => {
  return useMutation<FreeWinPostTransBalance, any, number>(
    "deposit-freewin",
    async (amount: number) => {
      const { data: response } = await axios.post(SET_DEPOSIT_FREEWIN, {
        amount,
      });
      return response;
    }
  );
};

export const useDepositPs3838 = () => {
  return useMutation<any, any, Ps3838TransBalance>(
    "deposit-ps3838",
    async (amount: Ps3838TransBalance) => {
      const { data: response } = await axios.post(SET_DEPOSIT_PS3838,
        amount,
      );
      return response;
    }
  );
};

export const useWithdrawDepositFreewin = () => {
  return useMutation<
    any,
    BodyWithdrawFromFreewinApiFreewinWithdrawPost,
    any
  >(
    "withdraw-deposit",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiFreewinWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_FREEWIN,
        sendWithdrawData
      );
      return response;
    }
  );
};


export const useWithdrawDepositBanks = () => {
  return useMutation<
    any,
    BodyWithdrawFromFreewinApiBanksWithdrawPost,
    any
  >(
    "withdraw-deposit-banks",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiBanksWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_BANKS,
        sendWithdrawData
      );
      return response;
    }
  );
}

export const useWithdrawDepositPs3838 = () => {
  return useMutation<
    any,
    Ps3838TransBalance,
    any
  >(
    "withdraw-deposit",
    async (sendWithdrawData: Ps3838TransBalance) => {
      const { data: response } = await axios.post(
        WITHDRAW_DEPOSIT_PS3838,
        sendWithdrawData
      );
      return response;
    }
  );
};

export const useWithdrawTron = () => {
  return useMutation<any, BodyWithdrawWalletApiTronUsdtWithdrawPost, any>(
    "withdraw-tron",
    async (sendWithdrawData: BodyWithdrawFromFreewinApiFreewinWithdrawPost) => {
      const { data: response } = await axios.post(
        WITHDRAW_TRON,
        sendWithdrawData
      );
      return response;
    }
  );
};

// export const useWithdrawTron = () => {
//   return useMutation<any, any, TronReceiverTransaction>(
//     "withdraw-tron",
//     async body => {
//       const { data: response } = await axios.post(WITHDRAW_TRON, body);
//       return response;
//     }
//   );
// };

export const useFreeWinLogin = () => {
  return useMutation<FreeWinURL,FreeWinLocale, FreeWinLocale>("freeWin-Login", async (body) => {
    const { data } = await axios.post(FREEWIN_LOGIN_RU, body);
    return data;
  });
};



// export async function confirmTOTP(
//   token: TokenFrom2FA
// ): Promise<void> {
//   const response = await axios.post(CONFIRM_TOTP, { token });
//   return response.data;
// }

export const useConfirmTOTP = () => {
  return useMutation<any, any, TokenFrom2FA>(
    "confirm-totp",
    async (token: TokenFrom2FA) => {
      try {
        const { data: response } = await axios.post(
          CONFIRM_TOTP,
          token
        );
        return response as any;
      } catch (error) {
        return error as any;
      }
    }
  );
};


export const useTOTPUrl = (options: any = {}) => {
  return useQuery<TotpResponse>(
    "totp-url",
    async () => {
      const { data } = await axios.get(GET_TOTP_URL);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useTOTPResetUrl = () => {
  return useMutation({
    mutationFn: () => {
      return axios.put(RESET_TOTP_URL);
    },
  });
};

export const useGetUser = (options: any = {}) => {
  return useQuery<ShowUserForUser>(
    "user-data",
    async () => {
      const { data } = await axios.get(USER_DATA);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useChangeUserSettings = () => {
  return useMutation<UserSettingsSchemas, any, ShowUserForUser>({
    mutationFn: body => {
      return axios.put(CHANGE_USER_DATA, body);
    },
  });
};

export const useGetTOTPSecretCode = () => {
  return useMutation(
    "totp-secret-code",
    async (sendVerifyData: VerifyTransaction) => {
      const { data: response } = await axios.post(
        GET_TOTP_SECRET_CODE,
        sendVerifyData
      );
      return response;
    }
  );
};
