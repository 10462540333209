import { useIntl } from "react-intl";
import { NavLink } from "../../../../../app/pages/main-page/components/NavLink";
import { FreeBetLogo } from "../../../../../app/pages/main-page/components/FreeBetLogo";
import { useLocation } from "react-router-dom";

export function MenuInner() {
  const intl = useIntl();
  const location = useLocation();
  const mainPath = location?.pathname?.split("/")[1];

  return (
    <>
      <div className="p-10 p-lg-0 container text-center align-items-center align-self-start align-self-lg-center">
        <div className="row align-items-center flex-column flex-lg-row fs-4 fs-lg-6">
          <div className="col col-lg-3 text-start d-none d-lg-block">
            <NavLink title={<FreeBetLogo />} to="/" />
          </div>

          {/* <div className="col text-end">
              {currentUser ? (
                <Link
                  className="link-danger d-flex align-items-center justify-content-end gap-4"
                  to="/crafted/pages/profile"
                >
                  <span className="fw-bold fs-6">
                    {walletData?.balance} USDT
                  </span>
                  <img
                    className="rounded-circle"
                    src={toAbsoluteUrl("/media/avatars/blank.png")}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </Link>
              ) : (
                <Link className="link-danger" to="/auth">
                  <span className="fw-bold fs-6">
                    {intl.formatMessage({ id: "MAIN.PAGE.LOGIN.TITLE" })}
                  </span>
                </Link>
              )}
            </div> */}
        </div>
      </div>

      {/* <div
        className="container text-center align-items-center"
        style={{ height: "59px" }}
      >
        <div className="h-100 row justify-content-center fw-bold px-20 align-items-center">
          <div className="col">
            <NavLink title={intl.formatMessage({ id: "HEADER.MAIN" })} to="/" />
          </div>
          <div className="col">
            <NavLink
              title={intl.formatMessage({ id: "HEADER.BONUSES" })}
              to="/builder"
            />
          </div>
          <div className="col">
            <NavLink
              title={intl.formatMessage({ id: "HEADER.CONTROL_PANEL" })}
              to="/bot"
            />
          </div>

          <div className="col">
            <NavLink
              title={intl.formatMessage({ id: "HEADER.AGENCY_OFFICE" })}
              to="/bot"
            />
          </div>

          <div className="col">
            <NavLink
              title={intl.formatMessage({ id: "HEADER.SUPPORT" })}
              to="/bot"
            />
          </div>
        </div>
      </div> */}
    </>
  );
}
