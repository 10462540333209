import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { resetPassword } from "../core/_requests";
import { useIntl } from "react-intl";
import { removeAllSpaces } from "../../../../_metronic/helpers";

const initialValues = {
  email: "",
};

export function ForgotPassword() {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_EMAIL" }))
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      resetPassword(values.email)
        .then(({ data }: any) => {
          setHasErrors(false);
          setLoading(false);
        })
        .catch(() => {
          setHasErrors(true);
          setLoading(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" })
          );
        });
    },
  });

  useEffect(() => {
    if (formik?.values?.email) {
      formik.values.email = removeAllSpaces(formik?.values?.email);
    }
  }, [formik?.values?.email]);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-8">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-6">
          {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}?
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.RESET.EMAIL" })}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_ERROR" })}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className="mb-8 bg-light-info p-8 rounded">
          <div className="text-info">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_SUCCESS" })}
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        {/* <label className="form-label fw-bolder text-gray-900 fs-6">Email</label> */}
        <input
          type="email"
          placeholder="user@example.com"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control bg-transparent",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-between pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
          disabled={loading}
        >
          <span className="indicator-label">
            {intl.formatMessage({ id: "AUTH.RESET.EMAIL_CONFIRM" })}
          </span>
          {loading && (
            <span className="indicator-progress">
              {intl.formatMessage({ id: "GLOBAL.WAIT" })}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link reloadDocument to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
}
