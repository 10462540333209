/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ConfirmRegistration } from "./components/ConfirmRegistration";
import styles from "./styles.module.scss";
import { toAbsoluteUrl } from "../../../_metronic/helpers";

import { Login } from "./components/Login";
import { ConfirmPassword } from "./components/ConfirmPassword";
import { ForgotPassword } from "./components/ForgotPassword";
import { NotificationRegistrationEditModal } from "./components/NotificationRegistrationEditModal";

export const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex  flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: `url(${toAbsoluteUrl(
          "/media/auth/bg4-dark.jpg"
        )}) center center / cover no-repeat`,
      }}
    >
      {/* <div className="d-none flex-center  d-xl-flex w-lg-50 pt-20 pt-lg-0 pe-5 ">
        <div className="d-flex align-items-center justify-content-end pt-20 w-100">
          {" "}
          <h4 className="display-2  w-25 text-center">
            <span
              className="text-gray-700 text-uppercase fw-bold fst-italic"
              style={{ fontFamily: "roboto", textTransform: "uppercase" }}
            >
              ps
            </span>
            <span
              className="text-gray-800 text-uppercase fw-bold fst-italic"
              style={{ fontFamily: "roboto", textTransform: "uppercase" }}
            >
              3838
            </span>
          </h4>
          <h4 className="display-2 w-25 text-end ms-20">
            <span
              className="text-gray-700 text-uppercase fw-bold fst-italic"
              style={{ fontFamily: "roboto", textTransform: "uppercase" }}
            >
              FW
            </span>
          </h4>
        </div>
      </div> */}
      {/* begin::Wrapper */}
      <div className="d-flex justify-content-center justify-content-lg-end align-items-center p-20 w-100">
        {/* begin::Wrapper */}
        <div className="bg-body shadow-sm d-flex flex-column flex-center rounded-4 w-md-550px h-lg-100 me-lg-20 p-15 p-lg-15 ">
          <div className="py-15 py-lg-0">
            <Outlet />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
    // <div
    //   className="form-wrap d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    //   style={{
    //     background: '#001228',
    //   }}
    //   // style={{
    //   //   background: `url(${toAbsoluteUrl(
    //   //     "/media/bg/pinacle.png"
    //   //   )}) center center / cover no-repeat`,
    //   // }}
    // >
    //   {/* begin::Content */}

    // </div>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />

      <Route path="registration">
        <Route index element={<Registration />} />
        <Route
          path="notification"
          element={<NotificationRegistrationEditModal />}
        />

        <Route path="confirm" element={<ConfirmRegistration />} />
      </Route>

      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/*" element={<ConfirmPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
