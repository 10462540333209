import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
// import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
// import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
// import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import { BetPage } from "../pages/bet/BetPage";
import { ConfirmOperation } from "../pages/ConfirmOperation";
import {
  confirmFreewinWithdrawal,
  confirmPs3838Withdrawal,
  confirmBanksWithdrawal,
  confirmWithdrawalTron,
} from "../modules/auth/core/_requests";
import { ConfirmTOTP } from "../pages/ConfirmTOTP";
import { UserContextProvider } from "../modules/profile/core/UserContextProvider";
import { QueryResponseProvider } from "../modules/profile/components/account-management/account-list/core/QueryResponseProvider";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import { ConfirmPassword } from "../modules/auth/components/ConfirmPassword";
import { AuthLayout } from "../modules/auth";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));

  return (
    <UserContextProvider>
      <QueryResponseProvider>
        <Routes>
          <Route
            path="freewin/confirm/*"
            element={<ConfirmOperation method={confirmFreewinWithdrawal} />}
          />
          <Route
            path="ps3838/confirm/*"
            element={<ConfirmOperation method={confirmPs3838Withdrawal} />}
          />
          <Route
            path="freewin/banks/confirm/*"
            element={<ConfirmOperation method={confirmBanksWithdrawal} />}
          />
          <Route
            path="tron/confirm/*"
            element={<ConfirmOperation method={confirmWithdrawalTron} />}
          />
          <Route element={<AuthLayout />}>
            <Route path="auth/forgot-password" element={<ForgotPassword />} />
            <Route path="auth/reset-password/*" element={<ConfirmPassword />} />
          </Route>

          <Route path="totp/confirm/*" element={<ConfirmTOTP />} />

          <Route element={<MasterLayout />}>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route
              path="auth/*"
              element={<Navigate to="/crafted/pages/profile" />}
            />
            {/* Pages */}

            <Route
              path="/"
              element={<Navigate to="/crafted/pages/profile" />}
            />
            {/* element={<MainPage />} */}

            <Route path="bet/:state" element={<BetPage />} />

            <Route
              path="crafted/pages/profile/*"
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/pages/wizards/*"
              element={
                <SuspensedView>
                  <WizardsPage />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/widgets/*"
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />
            <Route
              path="crafted/account/*"
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />

            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
          </Route>
        </Routes>
      </QueryResponseProvider>
    </UserContextProvider>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
