import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth";
import { useIntl } from "react-intl";
import { NavLink } from "../../../../app/pages/main-page/components/NavLink";

const itemClass = "ms-1 ms-lg-3";
// const btnClass =
// "btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px";
const userAvatarClass = "symbol-35px symbol-md-40px";

const Navbar = () => {
  const { currentUser } = useAuth();
  const intl = useIntl();

  if (!currentUser) {
    return (
      <div className="ms-auto">
        <NavLink
          title={
            <div className="h-100 fw-bold fs-6 text-betwallet d-flex align-items-center">
              {intl.formatMessage({ id: "MAIN.PAGE.LOGIN.TITLE" })}
            </div>
          }
          to="/auth"
        />
      </div>
    );
  }

  return (
    <div className="app-navbar w-100 w-lg-auto flex-shrink-0 d-flex gap-2">
      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="" />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Navbar };
