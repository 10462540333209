/* eslint-disable react-hooks/exhaustive-deps */
import {
  FC,
  useContext,
  useState,
  useEffect,
  useMemo,
  createContext,
} from "react";

import { useAllAccounts } from "./_requests";
import { useQueryRequest } from "./QueryRequestProvider";
import {
  PaginationState,
  WithChildren,
  stringifyRequestQuery,
} from "../../../../../../../_metronic/helpers";
import { useGetUser } from "../../../../core/_requests";
import { AccountData, AllAccountsData } from "./_models";
import { ShowUserForUser } from "../../../../../../../api/core-contract";

const HOST = process.env.REACT_APP_FREEWIN_SITE_HOST;

interface AccountContext {
  AllAccountsDataLoading: boolean;
  refetchAllAccountsData: () => void;
  response?: AllAccountsData[];
  query?: string;
}

const initialQueryState = {
  AllAccountsDataLoading: false,
  refetchAllAccountsData: () => {},
  response: undefined,
};

export const QueryResponseContext =
  createContext<AccountContext>(initialQueryState);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [response, setResponse] = useState<AllAccountsData[] | []>([]);

  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));

  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    data: allAccountsData,
    isFetching: isAllAccountsFetching,
    refetch: allAccountsRefetch,
  } = useAllAccounts();

  const {
    data: userData,
    isFetching: isUserDataFetching,
    refetch: userDataRefetch,
  } = useGetUser();

  const mergeData = (data2: AccountData) => {
    let result: AllAccountsData[] = [];

    if (Array.isArray(data2) && data2.length > 0) {
      data2.forEach((el) => {
        result.push({
          type: "PS3838",
          id: el.id,
          is_active: el.is_active,
          password: el.password,
          login: el.user_code,
          url: "https://www.ps3838.com/fr/",
        });
      });
    }

    if (result) {
      return result.sort((a: any, b: any) => a.id - b.id);
    } else {
      return result;
    }
  };

  const AllAccountsDataLoading = isUserDataFetching || isAllAccountsFetching;

  const refetchAllAccountsData = () => {
    allAccountsRefetch();
    userDataRefetch();
  };

  useEffect(() => {
    if (allAccountsData) {
      const res = mergeData(allAccountsData);

      setResponse(res);
    }
  }, [allAccountsData]);

  return (
    <QueryResponseContext.Provider
      value={{
        AllAccountsDataLoading,
        refetchAllAccountsData,
        response,
        query,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response || [];
};

const useQueryResponsePagination = () => {
  // const defaultPaginationState: PaginationState = {
  //   links: [],
  //   ...initialQueryState,
  // };

  // const { response } = useQueryResponse();
  // if (!response || !response.payload || !response.payload.pagination) {
  //   return defaultPaginationState;
  // }
  return undefined;

  // return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { AllAccountsDataLoading } = useQueryResponse();
  return AllAccountsDataLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
};
