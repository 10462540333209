import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { ID } from "../../../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL;

const GET_ALL_PS3838_ACCOUNTS = `${API_URL}/ps3838/all`;

const ACCOUNT_WALLET = `${API_URL}/ps3838/balance`;

const ACCOUNT_REGISTRATION = `${API_URL}/ps3838/login`;

export const useAllAccounts = (options: any = {}) => {
  return useQuery(
    "all-accounts",
    async () => {
      const { data } = await axios.get(GET_ALL_PS3838_ACCOUNTS);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useAccountWallet = () => {
  return useMutation("account-wallet", async (id: ID) => {
    const { data } = await axios.get(`${ACCOUNT_WALLET}?wallet_id=${id}`);
    return data;
  });
};

export const useRegistrationAccount = () => {
  return useMutation("account-registration", async (amount: {amount: number}) => {
    const { data } = await axios.post(ACCOUNT_REGISTRATION, amount);
    return data;
  });
};
