import type { FC } from "react";
import React from "react";

interface Props {}

export const FreeBetLogo: FC<Props> = React.memo(() => {
  return (
    <span
      // className="text-betwallet ls-1 fw-bold fs-2"
      className="text-gray-800 text-uppercase fw-bold fst-italic fs-2"
      style={{ fontFamily: "roboto", textTransform: "uppercase" }}
    >
      betwallet
    </span>
  );
});
