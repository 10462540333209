import { Footer } from "./Footer";

const FooterWrapper = () => {
  return (
    <div className="app-footer">
      <Footer />
    </div>
  );
};

export { FooterWrapper };
